import logo from './bg-alt.jpg'
import './HomePage.scss'
import React from "react";
import Footer from "../components/Footer/Footer";
import NagivationEn from '../components/Navigation/NagivationEn';
import FooterEn from '../components/Footer/FooterEn';

const HomePageEn = () => {
    return (
        <React.Fragment>
            <NagivationEn/>

        <div id="home" className="bg-white text-white">
            <div className="md:mt-16 relative ">
                <div className="container mx-auto">
                    <div className="flex">
                        <div className="md:w-1/2 w-full h-2000">
                            <div className="md:mt-16 md:pl-4">
                                <div className="relative md:hidden">
                                    <img src={logo} alt="Logo"/>
                                    <div className="image-overlay">
                                        <div className="text-dark text-5xl mt-8 font-bold">Demo Range</div>
                                        <div className="text-dark mt-2 text-xl">Best shooting range in the city!<br/>
                                            Train with us!
                                        </div>
                                        <div className="ml-4 text-left mt-6">
                                            <a href="#" data-id="00000000-0000-0000-0000-000000000000" data-color="515928" className="bookitone-booking-init text-secondary hover:border-b-2 border-secondary hover:border-solid text-xl">
                                                <button className="rounded p-2 bg-secondary hover:bg-secondary-darker text-white">
                                                    Buy a gun package
                                                </button>
                                           </a>
                                           </div>
                                        <div className="ml-4 text-left mt-2">  
                                           <a href="#"  data-id="00000000-0000-0000-0000-000000000000" data-color="515928" className="bookitone-booking-range-init text-secondary hover:border-b-2 border-secondary hover:border-solid text-xl">
                                                <button className="rounded p-2 bg-secondary hover:bg-secondary-darker text-white">
                                                    Reserve a bay
                                                </button>
                                           </a>

                                        </div>
                                    </div>
                                </div>
                                <div className="hidden md:block">
                                    <div className="text-dark text-header leading-header font-semibold">Demo Range</div>
                                    <div className="text-dark mt-2 text-xl">Best shooting range in the city!<br/>
                                        Train shooting with us!
                                    </div>
                                    <div className="mt-4">
                                        <a href="#" data-id="00000000-0000-0000-0000-000000000000" data-color="515928" className="hover:bg-secondary-darker hover:border-secondary-darker bookitone-booking-init text-white border-secondary border-2 hover:border-solid bg-secondary p-3 rounded font-medium">
                                        Buy a gun package
                                        </a>
                                        </div>
                                        <div className="mt-6">
                                        <a href="#" data-id="00000000-0000-0000-0000-000000000000" data-color="515928" className="hover:bg-grey-50 hover:border-secondary-darker hover:text-secondary-darker mt-2 bookitone-booking-range-init text-secondary hover:border-solid font-medium border-2 p-3 rounded border-secondary px-5">
                                        Reserve a bay
                                        </a>
                                    </div>
                                </div>
                                <div style={{fontSize: "0.7rem", color: "black", border: "1px grey solid", borderRadius: "8px", padding: "8px", margin: "8px", marginTop: "2rem"}}>
                                    <h2>Warning!</h2>
                                    <p>
                                    This page serves as a demo showcasing the integration capabilities of the BookitOne application with partner shooting range websites.
                                    The store functionality is available by clicking the "Buy a gun package" or "Reserve a bay" button.</p>
                                    <p>
                                    All services and their assigned prices are for demonstration purposes only and cannot be purchased.
                                    The payment window is connected to the test environment (sandbox) of the PlanetPay payment gateway. No money will be deducted from your account during the payment process.<br/>
                                    </p>
                                    <h2>Remember!</h2>
                                    <p>
                                        Providing any data on the demo page will not result in a sales agreement.
                                        All data entered in the demo form is protected to the same extent as in a real store.
                                        For more information on data processing, please refer to the privacy policy.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/2 w-full hidden md:block">
                            <div className="bg-secondary absolute right-0 top-0 pb-8 left-6/10">
                                <img src={logo} alt="Logo" className="mr-16 mt-8 max"
                                     style={{'maxHeight': '500px', 'marginLeft': '-4rem'}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*{*/}
            {/*    process.env.REACT_APP_VERSION &&*/}
            {/*    <div className={"footer"}>*/}
            {/*        {process.env.REACT_APP_NAME} v{process.env.REACT_APP_VERSION}*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
            <FooterEn/>
        </React.Fragment>
    );
};

export default HomePageEn;
